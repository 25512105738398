import React from 'react';

import Layout from '../components/Layout';

import cvPic1 from '../assets/images/cv_pic01.jpg';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Über mich</h2>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <h3>Nina Barahona</h3>

          <img
            src={cvPic1}
            alt=""
            className="cv-pic"
          />
          <p>
            Meine eigenen Geburtserfahrungen haben mich geprägt und mich zu der
            Frau gemacht, die ich heute bin. Ich wünsche jeder werdenden Mutter
            von Herzen, dass sie selbstbestimmt und in geschützter Atmosphäre
            ihr Kind zur Welt bringen darf. Wenn ich dazu meinen kleinen Teil
            beitragen darf, ist es mir eine grosse Ehre!
          </p>

          <p>
            Mein Name ist Nina Barahona. Ich lebe mit meinem Mann und unseren
            drei Söhnen (Mio Luis *2015, Paólo *2018 und León *2022) in der Stadt Luzern.
            Ich unterrichte Teilzeit als Primarlehrerin an einer 5./6. Klasse.
            2021 habe ich meine Ausbildung zur Doula bei <a href="https://www.doula.ch/doula-profil/nbarahona/" target="_blank" rel="noreferrer"> Doula
            Schweiz</ a> abgeschlossen.
          </p>

          <p>
            In meiner Freizeit bewege ich mich am liebsten mit meiner Familie an
            der frischen Luft, jogge durch die Natur, tanze mit meinem Mann,
            entspanne mit Yoga, geniesse gutes Essen und gute Gesellschaft von
            Freunden und Familie.
          </p>

        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
